





































import Vue from 'vue'
import { validatePhone, validateSms, checkPhone } from '@/assets/js/verification'
import { Component } from 'vue-property-decorator'
import SetPassword from '@/views/auth/SetPassword.vue'
@Component({
  components: {
    SetPassword
  }
})
export default class Register extends Vue {
  isAdopt = false
  btnLoading = false
  imgVerCation = {
    captcha_id: '',
    rate: 0
  }
  time = 0
  next = false
  smsTitle = '获取验证码'
  ruleForm = {
    phone: '',
    smsCode: ''
  }
  rules = {
    phone: [{ validator: validatePhone, trigger: 'blur' }],
    smsCode: [{ validator: validateSms, trigger: 'blur' }]
  }
  get isMob(): string {
    return this.$store.state.isMob
  }

  // 图片验证
  imgVerification(): void {
    this.$http
      .post(this.$api.captcha_img)
      .then((res: any) => {
        if (res.data.meta.status_code === 200) {
          this.$RVerify.configure({
            album: res.data.data
          })
          this.$RVerify.action((res: any) => {
            if (res) {
              this.isAdopt = true
              this.imgVerCation = res
              this.getSmsCode()
            } else {
              this.isAdopt = false
            }
          })
        } else {
          this.$message.error(res.data.meta.message)
        }
      })
      .catch((error: any) => {
        console.log('err', error)
      })
  }
  // 注册
  submitForm(): void {
    ;(this.$refs.ruleForm as vForm)?.validate((valid: any) => {
      if (valid) {
        this.btnLoading = true
        let row = {
          account: this.ruleForm.phone,
          code: this.ruleForm.smsCode,
          code_type: 1
        }
        this.$http
          .post(this.$api.check_code, row)
          .then((res: any) => {
            if (res.data.meta.status_code === 200) {
              this.next = true
              this.btnLoading = false
            } else {
              this.$message.error(res.data.meta.message)
              this.btnLoading = false
            }
          })
          .catch((error: any) => {
            this.btnLoading = false
            console.log('err', error)
          })
      } else {
        return false
      }
    })
  }
  // 检测是否注册过
  checkAccount(): void {
    let row = {
      account: this.ruleForm.phone,
      type: 2 // 1 登录 2 注册
    }
    this.$http
      .post(this.$api.checkaccount, row)
      .then((res: any) => {
        if (res.data.meta.status_code === 200) {
          this.imgVerification()
        } else {
          this.$message.error(res.data.meta.message)
        }
      })
      .catch((error: any) => {
        console.log('err', error)
      })
  }
  // 获取验证码
  getSmsCode(): void {
    let row = {
      phone: this.ruleForm.phone,
      captcha_id: this.imgVerCation.captcha_id,
      rate: this.imgVerCation.rate
    }
    this.$http
      .post(this.$api.send_code, row)
      .then((res: any) => {
        if (res.data.meta.status_code === 200) {
          this.time = 60
          this.timer()
          this.$message.success(`验证码已发送至 ${this.ruleForm.phone}。如超时未收到，请重新获取`)
        } else {
          this.$message.error(res.data.meta.message)
        }
      })
      .catch((error: any) => {
        console.log('err', error)
      })
  }
  // 点击获取验证码
  getSms(): void {
    let check = checkPhone(this.ruleForm.phone)
    if (!check.status) {
      this.$message.error(check.message)
    } else {
      this.checkAccount()
    }
  }
  timer(): void {
    if (this.time > 0) {
      this.time--
      this.smsTitle = `${this.time} 秒后重新获取`
      if (!this.time) {
        this.smsTitle = '重新获取'
      }
      setTimeout(this.timer, 1000)
    }
  }
  // 跳转密码登录
  passwordLogin(): void {
    this.$router.push({ name: 'login' })
  }
  // 微信登录
  clickWechat(): void {
    this.$router.push({ name: 'WeChatCode' })
  }
}
